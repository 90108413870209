.announcement {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding: 1em 1.5em 1em 1.5em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: #2d3033;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8rem;
  font-family: 'Montserrat', serif;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  flex: 0 !important;
}

.announcement-text {
  animation-name: announcement-background;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
}


@keyframes announcement-background {
  0% {
    margin-left: -1.25em;
    margin-right: 0;
  }

  100% {
    margin-left: 0;
    margin-right: -1.25em;
  }
}

.account-bar {
  background: #202124;
  width: 100%;
  text-align: right;
  display: block;
  box-sizing: border-box;
  border-top: 8px solid #202124;
}

.account-bar-buttons {
  background: #2d3033;
  padding: 8px;
  display: inline-flex;
}

.account-bar-buttons > a {
  display: inline-flex;
  text-transform: uppercase;
  padding: 3px 9px;
  border-radius: 4px;
  border: 1px solid white;
  color: white;
  align-items: center;
  vertical-align: middle;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  font-family: sans-serif;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  text-decoration: none;
  white-space: nowrap;
}

.account-bar-buttons > a:hover {
  border-color: #1976d2;
}

.account-bar-divider {
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: 0;
  height: 100%;
  border-right-width: thin;
  margin: 4px;
}

.account-bar-icon {
  margin-right: 8px;
  margin-left: -2px;
  font-size: 18px;
  display: inline-block;
  height: 1em;
  fill: white;
}

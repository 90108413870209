.footer {
    background: #2d3033;
    text-align: center;
    padding: 2em;
    color: white;
    font-weight: bold;
    text-decoration: none;
    width: 100%;
    display: block;
    box-sizing: border-box;
    font-size: 0.8rem;
    letter-spacing: .1em;
}

.footer-recaptha {
    margin-top: 0.8rem;
    color: lightgray;
    font-size: 0.6rem;
}

.footer-recaptha a {
    color: lightgray;
}
.app {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.app-available {
  background: rgb(237, 247, 237);
  color: rgb(30, 70, 32);
  padding: 1em;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}

.app-body {
  flex: 1;
  display: flex;
  width: 100%;
  position: relative;
  text-align: left;
}

.grecaptcha-badge {
  visibility: hidden;
}

.page {
  padding: 0.5em;
  flex: 1;
  min-width: 0;
}

.page-content {
  flex: 1;
  font-weight: bold;
  font-size: 0.8rem;
  font-family: 'Montserrat', serif;
}

.breadcrumbs {
  color: grey;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8rem;
  letter-spacing: .1em;
  font-family: 'Montserrat', serif;
  margin-bottom: 0.5em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 0.5em;
  align-items: center;
  display: flex;
  line-height: 42px;
  white-space: nowrap;
  position: relative;
  min-width: 0;
  overflow: hidden;
}

.breadcrumbs-overflow {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 2em;
  background: linear-gradient(to right, rgba(255, 0, 0, 0), white, white);
}

.breadcrumbs a {
  text-decoration: none;
  color: grey;
}

.breadcrumbs:last-child a {
  color: black;
}

.breadcrumbs a:last-child {
  color: black;
}

.subtext {
  color: #829191;
}

.blue-link a,
.blue-link a:visited {
  color: #1976d2 !important;
  text-decoration: underline !important;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url('./Assets/Font/Montserrat.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', serif;
  min-width: 350px;
}

.container {
  max-width: 1100px;
  min-width: 350px;
  margin: 0 auto;
}

.v-divider {
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  height: auto;
  border-right-width: thin;
  align-self: stretch;
  margin: 4px;
}

.svg-icon {
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentcolor;
  flex-shrink: 0;
}
.common-font-black,
.common-font {
  font-size: 0.9rem;
  font-family: 'Montserrat', serif;
}

.common-font-black {
  color: black;
}

.common-font-black h2 {
  margin: 8px 0 8px 0;
}

.flex-column {
  display: flex;
  align-items: center;
}

.grecaptcha-badge {
  z-index: 999;
}
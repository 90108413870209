.menu {
  display: flex;
  z-index: 199;
}

.menu-ad {
  padding: 0.5em;
  flex: 1;
  box-sizing: border-box;
}

.menu-list-container {
  display: flex;
  overflow: hidden;
  width: 281px;
  min-width: 281px;
  transition: max-width 0.3s ease, min-width 0.3s ease;
}

.menu-list {
  width: 280px;
  min-width: 280px;
  background: white;
  border-right: 1px solid #BCC5D0;
  z-index: 99;
  padding-top: 8px;
}

.menu-item {
  border-bottom: 1px solid #E0E0E0;
  box-sizing: content-box;
}

.menu-item .menu-item-group,
.menu-item a {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  font-family: 'Open Sans', serif;
  font-size: 16px;
  flex: 1;
  text-decoration: none;
  color: black;
  height: 32px;
  cursor: pointer;
}

.menu-item .menu-item-group:hover,
.menu-item a:hover {
  background: #F5F5F5;
}

.menu-item a.active {
  background: rgba(25, 118, 210, 0.08);
}

.menu-item svg {
  font-size: 24px;
}

.menu-text {
  flex: 1;
}

.menu-item .menu-icon {
  color: rgba(0, 0, 0, 0.54);
  margin-right: 32px;
}

.menu-item-children a {
  padding-left:32px ;
}

.menu-item-children {
  max-height: 0;
  overflow: hidden;
  transition: max-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.menu-item.expanded .menu-item-children {
  max-height: 600px;
}

.menu-item.expanded .menu-arrow {
  transform: rotate(180deg);
}

@media (max-width: 1000px) {
  .menu-list-container-show > .menu-overlay {
    flex: 1;
    background: black;
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .menu-list-container {
    width: 280px;
    min-width: 280px;
  }

  .menu {
    width: 0;
    overflow: visible;
  }

  .menu-list-container-hide {
    width: 0;
    min-width: 0;
  }
}

.header {
  background: #2d3033;
  width: 100%;
  text-align: center;
  display: block;
  box-sizing: border-box;
}


.header > img {
  height: 110px;
  margin: 0 auto;
  padding: 0.5em;
  display: block;
}

@media (max-width: 400px) {
  .header > img {
    height: 90px;
  }
}


.search-bar {
  width: 100%;
  background: #f8f9fa;
  text-align: center;
  border-bottom: 1px solid #bcc5d0;
}

.search-bar-container {
  padding: .5em .5em 0;
  display: flex;
  align-items: stretch;
}

.search-bar-menu {
  display: none;
}

.search-bar-menu-tooltip {
  display: none;
  z-index: 200 !important;
}

.search-bar-menu-open {
  background: white;
  border-top: 1px solid #BCC5D0;
  border-left: 1px solid #BCC5D0;
  border-right: 1px solid #BCC5D0;
  margin-bottom: -1px;
}

.search-bar-menu-tooltip .MuiTooltip-tooltip {
  background-color: #1976d2 !important;
  text-transform: uppercase;
  font-family: 'Montserrat', serif;
  padding: 4px 6px !important;
  font-size: 11px !important;
  font-weight: 500;
  margin: 0 0 14px !important;
  line-height: normal !important;
}

.search-bar-menu-tooltip .MuiTooltip-arrow {
  color: #1976d2 !important;
}

.search-bar-menu-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-align: center;
  overflow: visible;
  padding: 10px;
}

.search-bar-menu-button svg {
  user-select: none;
  width: 24px;
  height: 24px;
  display: inline-block;
  fill: rgb(85, 90, 98);
  flex-shrink: 0;
}

.search-bar-search {
  padding-bottom: .5em;
  flex: 1 1;
}

.search-bar-search-wrapper {
  padding: 2px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  display: flex;
  align-items: center;
  flex: 1;
}

.search-input {
  flex: 1;
  border: 0;
  background-color: transparent;
  color: #000;
  font-family: "Open Sans", HelveticaNeue-Light, "Segoe UI", "Helvetica Neue", Helvetica, Raleway, Arial, sans-serif;
  height: 36px;
  line-height: 36px;
  margin: 0;
  padding: 3px 4px;
  width: 100%;
  font-size:14px;
  box-sizing: border-box;
  display: inline-block;
  outline-offset: 0;
  outline: none;
}

.search-input:-webkit-autofill,
.search-input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.search-select {
  width: 76px !important;
  border: 0 !important;
  border-radius: 0 !important;
  font-size:14px;
  line-height: 1.15;
  margin: 0;
  padding: 3px 0 3px 8px;
  height: 36px;
  outline: none;
  outline-offset: 0;
  font-family: "Open Sans", HelveticaNeue-Light, "Segoe UI", "Helvetica Neue", Helvetica, Raleway, Arial, sans-serif;
  appearance: auto;
  background: white;
}

.clear-button {
  background: white;
  border: 0;
  padding: 10px 4px 10px 0;
  margin: 0;
  display: inline-block;
  height: 40px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: pointer;
}

.clear-button svg {
  height: 20px;
  fill: rgba(0, 0, 0, 0.54);
  width: 20px;
}

.clear-button-hidden {
  display: none;
}

.search-button {
  background: white;
  border: 0;
  padding: 8px;
  margin: 0;
  display: inline-block;
  height: 40px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: pointer;
}

.search-button svg {
  height: 24px;
  fill: #1976d2;
  width: 24px;
}

@media (max-width: 1000px) {
  .search-bar-menu {
    display: flex;
    align-items: flex-start;
    margin-right: 8px;
  }

  .search-bar-menu-tooltip {
    display: initial;
  }
}
